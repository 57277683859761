<template>
  <b-row class="rowbg">
    <b-col md="12">
      <b-card>
        <b-card-title>Ridder en de Prins</b-card-title>
        <b-card-text>
          Gelukkig mogen we met Piet en Sint in 2025 het weer huiselijk gezellig maken. Wij, Anouk, Kristel, Yorick en Ludo, zorgen met ons team van acteurs voor sociaal verrassende interactie en zorgen zo voor een onvergetelijke Sinterklaasshow, heerlijke Pakjesavond of authentieke Theatervoorstelling. Wij geloven in Sinterklaas, u toch ook?<b-link to="/wie" class="detaillink">Wie wij zijn?</b-link>
        </b-card-text>
      </b-card>
    </b-col>

    <b-col lg="6" class="pb-3">
      <b-card title="Show op locatie" tag="article">
        <b-link to="/sinterklaasshow">
          <b-img v-bind:src="$imagestore2 + 'listings/o/40.jpg'" fluid class="card-image"></b-img>
        </b-link>
        <b-card-text class="card-text-clamped">
          Wij nemen iedereen mee in de wereld van Piet en Sint. Afwisseling is
          het toverwoord. Piet neemt de kinderen mee op avontuur...
        </b-card-text>
        <b-link to="/sinterklaasshow" class="detaillink">Laat je verrassen</b-link>
      </b-card>
    </b-col>
  
    <b-col lg="6" class="pb-3">


      <b-card title="Pakjesavond!?" tag="article">
        <b-link to="/nieuws">
          <b-img v-bind:src="
            $imagestore2 + 'images/artikelen/website_pakjesavond2022.png'
          " fluid class="card-image"></b-img>
        </b-link>
        <b-card-text class="card-text-clamped">
          Wij komen langs! Voor een geweldige pakjesavond kun je Piet&Piet of
          Piet&Sint uitnodigen om te dansen, zingen en pepernoten te strooien.

          Neem contact op via ons contactformulier, we proberen dan zo snel
          mogelijk te laten weten of jouw aanvraag mogelijk is.
        </b-card-text>
        <b-link to="/nieuws" class="detaillink">Het laatste nieuws...</b-link>
      </b-card>


    </b-col>




    

    <b-col lg="6" class="pb-3">
      <b-card title="In het theater, een hele beleving" tag="article">
        <b-link to="/voorstelling">
          <b-img v-bind:src="$imagestore2 + 'listings/o/41.jpg'" fluid class="card-image"></b-img>
        </b-link>
        <b-card-text class="card-text-clamped">
          In het theater hangt de 5 december sfeer. In onze geheel eigen
          producties vertolken Piet en Sint de hoofdrollen.
        </b-card-text>
        <b-link to="/voorstelling" class="detaillink mt-auto">Theater om te genieten</b-link>
      </b-card>
    </b-col>

    <b-col lg="6" class="pb-3">
      <b-card title="Sinterklaasje kom maar binnen" tag="article">
        <b-link to="/videoclipsenfotos">
          <b-img v-bind:src="$imagestore2 + 'images/artikelen/sinterklaasjekommaarbinnen.jpg'" fluid class="card-image"></b-img>
        </b-link>
        <b-card-text class="card-text-clamped">
          
          Alle liedjes zijn hier te zien.<br> Zing je met on mee? Olé!
          
          <br>
        </b-card-text>
        <b-link to="/videoclipsenfotos" class="detaillink mt-auto">Alle liedjes</b-link>
      </b-card>
    </b-col>

    
    
  </b-row>
</template>

<script>
export default {
  name: "Hoofdpagina",
};
</script>

<style>

</style>